import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import MapComponent from "../Pages/Map/MapComponent";
import Pusher from "pusher-js";
import TrackRider from "../Pages/Map/TrackRider";
import Navbar from "./Navbar";
import { VARIABLES } from "../../Constants";

const Content = (props) => {
  const [apiRideData, setApiRideData] = useState([]);
  console.log(apiRideData);
  const [mapCenter, setMapCenter] = useState([]);
  const [currentRider, setCurrentRider] = useState(null);
  const [rider, setRider] = useState(null);
  const [riders, setRiders] = useState([]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const getRiders = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/riders",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setRiders(
          response.data.map((listRider) => {
            return {
              value: listRider.id,
              label: listRider.name,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRiderCoordinates = (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/rider-tracker-data/" + id,
      headers: {},
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.warn(response.data.data[0]);
        setCurrentRider(response.data.data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (rider !== null) {
      getRiderCoordinates(rider);
    }
  }, [rider]);

  useEffect(() => {
    getRiders();
  }, [location.pathname]);

  const websocketConnection = () => {
    console.log("Inside the pusher");
    const pusher = new Pusher("792160be1db0803c71f5", {
      cluster: "ap2",
      useTLS: true, // Enable secure WebSocket connection
    });

    // Subscribe to the 'APPS' channel
    const channel = pusher.subscribe("SKOM");

    // Bind to the '2020' event
    channel.bind("2024", (data) => {
      console.log(data.message);
      setApiRideData(data.message);
      // Handle the event data as needed
    });

    // Clean up when the component unmounts
    return () => {
      pusher.disconnect();
    };
  };

  useEffect(() => {
    websocketConnection();
  }, []);

  return (
    <>
      <Navbar riders={riders} setRider={setRider} isLoading={isLoading} />
      <Routes>
        <Route
          path="/"
          element={
            <MapComponent apiRideData={apiRideData} position={mapCenter} />
          }
        />
        <Route
          path="/trackrider"
          element={<TrackRider currentRider={currentRider} />}
        />
      </Routes>
    </>
  );
};

export default Content;
