import { useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import KML from "./kml";

function RoutingMachine() {
  const map = useMap();
  useEffect(() => {
    fetch("https://ridewithgps.com/trips/144212058.kml")
      .then((res) => res.text())
      .then((kmltext) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmltext, "text/xml");
        const track = new L.KML(kml);
        console.log(track);
        map.addLayer(track);
        const bounds = track.getBounds();
        map.fitBounds(bounds);
      });
  }, [map]);
  return null;
}

export default RoutingMachine;
