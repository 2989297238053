import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

function Navbar({ riders, setRider, isLoading }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (data) => {
    setRider(data.value);
  };

  return (
    <nav
      class="navbar bg-body-tertiary position-static top-0"
      style={{ width: "100%" }}
    >
      <div class="container-fluid d-flex align-items-center">
        {location.pathname === "/trackrider" ? (
          <>
            <button
              type="button"
              className="btn btn-secondary my-1"
              onClick={() => navigate("/")}
            >
              Home
            </button>
            {isLoading ? (
              <>
                <div
                  class="spinner-border text-danger ms-auto me-3"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </>
            ) : (
              ""
            )}

            <form class="d-flex" role="search" style={{ zIndex: 1000 }}>
              <Select
                options={riders}
                placeholder={"Enter rider"}
                onChange={handleChange}
              />
            </form>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/trackrider")}
          >
            Track Rider
          </button>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
