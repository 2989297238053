import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Base from './Component/Base/Base'

const App = () => {
  return (
    <>
    <BrowserRouter>
    <Base />
    </BrowserRouter>
    </>
  )
}

export default App