import React from 'react'
import Header from "./Header";
import Content from './Content';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

const Base = (props) => {
  // const location = useLocation();
  // const trackerParameter = new URLSearchParams(location.search).get("trackerid")
  // console.log(trackerParameter)
  // let arr = trackerParameter.split(",")
  // console.log(arr);

  return (
    <>
      {/* <div className="nk-app-root"> */}
        {/* <!-- wrap @s --> */}
        {/* <div className="nk-wrap "> */}
            {/* <!-- main header @s --> */}
              {/* <Header /> */}
              {/* <Footer /> */}
            {/* <!-- main header @e --> */}
            {/* <!-- content @s --> */}
            {/* { arr.map((item) => {return <Content item={item} />})} */}
            {/* <Content /> */}
             
            {/* <!-- content @e --> */}
            {/* <!-- footer @s --> */}
            
            {/* <!-- footer @e --> */}
        {/* </div> */}
        {/* <!-- wrap @e --> */}
    {/* </div> */}
    {/* <Header /> */}
    <Content />
    {/* <Footer /> */}
    </>
  )
}

export default Base