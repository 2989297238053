import React from "react";
import {
  ImageOverlay,
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import petrolPump from "../../Images/indian-oil.png";
import Cyclist from "../../Images/cycle.png";
import Cyclist6 from "../../Images/pin.png";
import { Icon } from "leaflet";
import L from "leaflet";
import moment from "moment";
import { useEffect } from "react";
import { useRef } from "react";
import Track from "./Track";

const MapComponent = (props) => {
  console.log(props.position);
  console.log(props.apiRideData);
  const bounds = [
    [22.5178, 79.70277999999998],
    [22.4978, 79.72277999999998],
    // [43.78098644922989,15.647438805314396],
    // [43.78098644922989,15.655914504316957],
  ];

  return (
    <>
      {/* {position.length>0 ?  */}
      <MapContainer
        className="map"
        bounds={bounds}
        zoom={7}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='Powered By <a href="http://elorca.com/">Elorca</a>   
              Tracked By <a href="https://timetherace.co.in/">Time The Race</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* Checkpoints cordinates starts from here */}
        {/*<>
          <Marker position={[21.18198222222222, 79.71277999999998]} icon={new Icon({ iconUrl: Cyclist6, iconSize: [41, 41], iconAnchor: [12, 41] })}>
            <Popup>
              <div className='fw-bold'> Checkpoint City : Checker Pin Point </div>
            </Popup>
          </Marker>
        </>*/}

        {/* Route From Source to Destination (Srinagar to kanykumari) */}
        {props.apiRideData.map((item, index) => {
          console.log(item);
          // var rider_name = item.vn === '' ? ["No Rider", "No Bibno"] : item.vn.split("_")
          // console.log(rider_name)
          return (
            <Marker
              key={index}
              position={[
                parseFloat(item && item.la === null ? 0 : item.la),
                parseFloat(item && item.lo === null ? 0 : item.lo),
              ]}
              icon={
                new Icon({
                  iconUrl: Cyclist,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                })
              }
            >
              <Popup>
                <div className="fw-bold">
                  {" "}
                  Rider name : {item && item.rn === null
                    ? "No Rider"
                    : item.rn}{" "}
                </div>
                <div className="fw-bold">
                  {" "}
                  Rider bibno :{" "}
                  {item && item.vn === null ? "No Bibno" : item.vn}{" "}
                </div>
                <div className="fw-bold">
                  {" "}
                  Rider Speed : {item && item.sp === null ? 0 : item.sp}{" "}
                </div>
              </Popup>
            </Marker>
          );
        })}
        <Track />
      </MapContainer>
      {/* :
          ""} */}
    </>
  );
};

export default MapComponent;
