import React, { useState } from "react";
import {
  ImageOverlay,
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import petrolPump from "../../Images/indian-oil.png";
import Cyclist from "../../Images/cycle.png";
import Cyclist6 from "../../Images/pin.png";
import { Icon } from "leaflet";
import L from "leaflet";
import moment from "moment";
import { useEffect } from "react";
import { useRef } from "react";
import Track from "./Track";

const TrackRider = ({ currentRider }) => {
  const bounds = [
    [22.5178, 79.70277999999998],
    [22.4978, 79.72277999999998],
    // [43.78098644922989,15.647438805314396],
    // [43.78098644922989,15.655914504316957],
  ];

  return (
    <>
      {/* {position.length>0 ?  */}
      <MapContainer
        className="map"
        bounds={bounds}
        zoom={7}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='Powered By <a href="http://elorca.com/">Elorca</a>   
              Tracked By <a href="https://timetherace.co.in/">Time The Race</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* Checkpoints cordinates starts from here */}
        {/*<>
          <Marker position={[21.18198222222222, 79.71277999999998]} icon={new Icon({ iconUrl: Cyclist6, iconSize: [41, 41], iconAnchor: [12, 41] })}>
            <Popup>
              <div className='fw-bold'> Checkpoint City : Checker Pin Point </div>
            </Popup>
          </Marker>
        </>*/}

        {/* Route From Source to Destination (Srinagar to kanykumari) */}
        {currentRider !== null && (
          <Polyline
            positions={currentRider.coordinates}
            pathOptions={{ color: "blue" }}
          />
        )}
        <Track />
      </MapContainer>
      {/* :
          ""} */}
    </>
  );
};

export default TrackRider;
